<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo d-flex align-items-center">
      <aiot-logo style="width: 60px;height: 60px"/>
      <span class="x-text-bold text-primary ml-1 font-large-1">
        极熊座
      </span>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 d-flex flex-column text-center align-items-center">
        <h2 class="mb-1">
          很抱歉，您访问的页面不存在
        </h2>
        <p class="mb-2 text-secondary">
          输入地址有误或该地址已被删除，您可以
        </p>

        <b-button
          v-ripple.400="$x.ripple.white"
          variant="primary"
          class="mb-2 m-auto"
          :to="{path:'/'}"
        >
          返回首页
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import AiotLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    AiotLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
